var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "magazineListItem",
      class: { "magazineListItem--small": _vm.small },
    },
    [
      _c(
        "link-wrap",
        { attrs: { link: _vm.article.link } },
        [
          _vm.hasPreview
            ? _c("image-tag", {
                staticClass: "magazineListItem__teaserImages",
                attrs: { image: _vm.article.preview[0], definition: "teaser" },
              })
            : _c("image-tag", {
                staticClass: "magazineListItem__teaserImages",
                attrs: {
                  image: _vm.article.teaserImages[0],
                  definition: "teaser",
                },
              }),
          _vm._v(" "),
          _c("span", {
            staticClass: "magazineListItem__headline",
            domProps: { innerHTML: _vm._s(_vm.article.title) },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "magazineListItem__teaser" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.article.teaser) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "magazineListItem__link" }, [
            _vm._v(_vm._s(_vm.$t("magazine.list.more"))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }