




































import {isEmpty} from '@labor-digital/helferlein';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import ImageTag from '../../../Component/Misc/Image/ImageTag/ImageTag.vue';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'MagazineListItem',
    components: {LinkWrap, ImageTag},
    props: {
        article: null as PlainObject,
        small: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasPreview(): boolean
        {
            return !isEmpty(this.article.preview);
        }
    }
};
