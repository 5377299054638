





































import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {AppStoreKeys} from '../../../../../AppStoreKeys';
import Icon from '../../../../../Component/Misc/Icon/Icon.vue';
import LinkWrap from '../../../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'CategoryList',
    components: {LinkWrap, Icon},
    props: {
        context: null as ContentElementContext,
        isScrolled: Boolean,
        showCloseIcon: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        classes()
        {
            return {
                'careCategoryList--scrolled': this.isScrolled
            };
        },
        categories(): PlainObject
        {
            return this.context.data.get('categories', []);
        },
        rootLink(): string
        {
            return this.context.data.get('rootLink');
        }
    },
    methods: {
        toggleSidebar(toggle)
        {
            this.context.store.set(AppStoreKeys.SIDEBAR_DIRECTION, toggle);
        }
    },
    mounted()
    {
        const category = this.categories.filter(
            category => category.id === parseFloat(this.context.initialQuery.filter.categories));

        if (!isEmpty(category)) {
            this.context.store.set(AppStoreKeys.CARE_HEADLINE_OVERRIDE, category[0].title);
        }
    }
};
