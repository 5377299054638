var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "careCategoryList",
      class: _vm.classes,
      attrs: { id: "care-categories-container" },
    },
    [
      _c("span", { staticClass: "careCategoryList__listHeadline" }, [
        _vm._v(_vm._s(_vm.$t("care.list.topics"))),
      ]),
      _vm._v(" "),
      _vm.showCloseIcon
        ? _c("icon", {
            staticClass: "careCategoryList__categorySwitch",
            attrs: { icon: "close", color: "company" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSidebar(null)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "careCategoryList__list" },
        [
          _c(
            "li",
            { staticClass: "careCategoryList__listItem" },
            [
              _c("link-wrap", { attrs: { link: _vm.rootLink } }, [
                _vm._v(_vm._s(_vm.$t("care.list.allArticles"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.categories, function (category) {
            return _c(
              "li",
              { staticClass: "careCategoryList__listItem" },
              [
                _c("link-wrap", { attrs: { link: category.url } }, [
                  _vm._v(_vm._s(category.title)),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }