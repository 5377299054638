import { render, staticRenderFns } from "./CareDisplayList.vue?vue&type=template&id=696f697c&scoped=true&"
import script from "./CareDisplayList.vue?vue&type=script&lang=ts&"
export * from "./CareDisplayList.vue?vue&type=script&lang=ts&"
import style0 from "CareDisplayList.sass?vue&type=style&index=0&id=696f697c&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "696f697c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('696f697c')) {
      api.createRecord('696f697c', component.options)
    } else {
      api.reload('696f697c', component.options)
    }
    module.hot.accept("./CareDisplayList.vue?vue&type=template&id=696f697c&scoped=true&", function () {
      api.rerender('696f697c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ContentElement/Persona/CareDisplay/List/CareDisplayList.vue"
export default component.exports